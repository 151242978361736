<template>
  <div>
    <b-card >
      <b-row class="text-center">
        <b-col sm="12" md="3">
          <img
            width="25%"
            style="border-radius:50%;"
            src="@/assets/user_1.png"
            alt=""
          />

          <b-card-text>
            <p class="school32 text-center">Раҳмонберди Мадазимов<br />(1875–1933)</p></b-card-text
          >
        </b-col>
        <b-col md="6">
          <b-card-text>
          <p class="TextBlack17">
            Раҳмонберди Мадазимов (1875–1933) — Қирғизистон театр ҳаракатининг
            асосчиси, Ўрта Осиёдаги иккинчи енг кекса профессионал театри бўлмиш
            Бобур номли Ўш Давлат академик ўзбек мусиқали драма театрининг
            биринчи асосчиси, ташкилотчиси, директори, бадиий раҳбари, бош
            режиссори ҳамда ёзувчиси. Шунингдек, Қирғизистон маданияти ва
            санъати ривожига катта ҳисса қўшган. 1914-йили Раҳмонберди Мадазимов
            раҳбарлигида Ўш шаҳрида рус-тузем мактаби ўқитувчиси Болтиҳожи
            Султонов билан биргаликда театр тўгараги ташкил етилган. Мирзо
            Раҳмонберди ҳожи Мадазимов раҳбарлигида Ўшнинг зиёли ўқитувчилари
            Иброҳим Мусабойев, Бекназар Назаров, Жўрахон Зайнобиддинов, Назирхон
            Камолов, Aбдурашид Ешонхонов, A.Саидовлар билан бирга 1918-йили
            Қирғизистонда биринчи бўлиб мусулмон актёрлардан иборат Туркистон
            фронти ҳарбий кенгаши қошидаги консерт бригадаси негизида Ўшда
            хаваскор театр тўгарагини тузган ва труппа биринчи директори, бадиий
            раҳбари ва режиссёри Раҳмонберди ҳожи Мадазимов Қирғизистондаги
            театр ҳаракатини биринчи асосчиси бўлган[4][5]. 1919 йили театр
            тўгараги театр драма труппасига айлантирилган. Кейинчалик труппага
            Aбдуқодир Ишоқов, Исроилжон Исмоилов, Жалил Собитовлар қўшилишган.
            Ушбу труппа Қирғизистоннинг жанубида нафақат театр санъатини, балким
            профессионал мусиқа санъатининг ривожланишига ҳам юксак ҳисса
            қўшган. Чунки труппанинг репертуарида драма асарларидан ташқари,
            кўплаб консерт дастурлари намойиш етиларди, шунингдек мусиқали
            спектакллар қўйиларди, бу профессионал мусиқачилар йетишиб чиқишига
            алохида босқич бўлди. Театр труппаси Бобур (Киров) номли Ўш Давлат
            академик ўзбек мусиқали драма театрини тузишга асос бўлган. Бобур
            номли Ўш ўзбек академик мусиқали-драма театри Ҳамза номидаги ўзбек
            миллий академик драма театридан (1913-27 феврал 1914 йили ташкил
            етилган) сўнг Марказий Осиёдаги иккинчи енг кекса профессионал
            театрдир[6] Раҳмонберди хожи Мадазимов театр труппаси билан Ўшнинг
            барча туманларини айланиб, консерт ва спектакллар қўйиб чиқди ва
            босмачиликка санъат қуроли орқали кураш олиб борди. Туманларда
            спектакллар, консертлар қўйиб, босмачиларни жойлашган йерларини ва
            уларни яшириб қўйган қурол-яроғлардан хабар топиб, ўзининг қудаси,
            Ўш шаҳар милициясининг асосчиси ва биринчи бошлиғи Болтиҳожи
            Султонов билан бирга босмачиларни тор-мор етишда фаол иштирок етди.
            Босмачилар жойлашган туманлардан қизилларни қурол-яроғларини
            аравасига яшириб ўтказган ва қизилларни қурол-яроғ билан
            таъминлаган. Маърифатчи ва инқилобчи Болтиҳожи Султонов ва Фозилбек
            Қосимбековлар театр труппасини оёққа тургизишда театр труппасини
            асосчиси, директори ва бадиий раҳбари Раҳмонберди Мадазимовга ёрдам
            бердилар. Театр даврнинг долзарб масалаларини кўтариб чиқиш, енг
            яхши песаларни саҳналаштиришга асосан 1929 йилдан бошлаб киришди.
            Қирғизистон халқ комиссарлари Кенгашининг қарорига биноан шу йилдан
            театр профессионал жамоа сифатида иш бошлади. . Раҳмонберди
            Мадазимовнинг Қирғизистоннинг театр санъати асосчиси ва қатнашчиси
            сифатида хизматлари Қирғизистон фанлар Aкадемияси томонидан 1987
            йили чиқарилган Ўш вилояти Енсиклопедиясининг 110 бетида қайд
            етилган. Ҳамда ушбу Енсиклопедиянинг 110 бетида ўғли Жўрахоннинг
            расми чиқарилган. 2014 йил Давлат тили ва енсиклопедия маркази
            томонидан нашр етилган „Қирғизистон“ миллий Енсиклопедияси 5 томмда
            ва театрнинг 90 йиллик юбилейига чиқарилган феврал 2010 йилда нашр
            етилган Aбдуғани Aбдуғафуровнинг „Ўшнинг Aкадемик театри“ китобининг
            1 бетида Раҳмонберди Мадазимовнинг Бобур номли Ўш давлат академик
            мусиқали драма театрининг ташкил етишда ва фаолият кўрсатишдаги
            хизматлари акс еттирилган. Раҳмонберди ҳожи Мадазимов Ўшда таниқли
            ёзувчи бўлган. Унинг иккита „Ўшнинг тавсифи“ ва "Исмлар имлоси"
            асарлари 1914 ва 1915 йиллари Тошкентда нашр етилган. Бундан
            ташқари, театр учун кичкина драмалар, пъйесалар ва консерт
            дастурлари муаллифи бўлган ва уларни саҳналаштирган. Раҳмонберди
            Мадазимов 1932 йили Ўзган шаҳрига кўчиб кетганлиги сабабли театрдан
            ишдан кетади ва 1933 йил май ойининг охирида Ўзган шаҳрида
            қийинчилик, очлик вақтлари, тут пишиғида вафот етади. Ўзган
            шаҳрининг „Йетти Султон“ қабристонига дафн етилган. Мирзо
            Раҳмонбердининг иккала ўғли ҳам отасининг йўлини давом еттириб Ўш
            ўзбек драма театрида кўп йиллар давомида ишлашган. Ўринбой ва
            Жўрахон Раҳмоновлар Қирғизистон маданияти ва санъати ривожланишига
            катта ҳисса қўшишган. Ўш шаҳридаги Бобур (Киров) номли драма
            театрининг асосчиларидан бири бўлишган.
          </p>
        </b-card-text>
        </b-col>
        <b-col md="3"></b-col>
      </b-row>
    </b-card>
    <!-- <b-card style="background-color:#EEEEEE">
      <b-container style="background-color:#EEE4CD;border-radius:20px">
        
      </b-container>
      <b-container>
        <b-row>
          <b-col>
            <b-button
              @click="$router.push({ name: 'jadidchilar_info' })"
              variant="outline-danger"
            >
              {{ $t("Back") }}
            </b-button>
          </b-col>
          
        </b-row>
      </b-container>
    </b-card> -->
  </div>
</template>
<script>
export default {};
</script>
<style></style>
